<template>
    <div class="main-container">
      <h5 class="pageTitle">드론 보험 수정/삭제</h5>
      <div class="form-container">
        <div class="form-group">          
          <label for="model">모델(타입)</label>
          <input
                v-model="form.model"
                type="text"
                class="input-box"
                readonly
           />
          <!-- <select v-model="form.model_ID" class="select-box">
            <option v-for="model in droneModels" :key="model.uuid" :value="model.uuid">
              {{ model.model }}
            </option>
          </select> -->
        </div>
  
        <div class="form-group">
          <label for="name">보험명</label>
          <input v-model="form.name" type="text" class="input-box" />
        </div>
  
        <div class="form-group">
          <label for="expire_date">보험만료일</label>
          <input v-model="form.expire_date" type="date" class="input-box wide-input" />
        </div>
  
        <div class="form-group">
          <label for="smsYN">SMS 예약 여부</label>
          <select id="smsYN" v-model="form.sms_YN" class="input-select">
            <option :value="true">예</option>
            <option :value="false">아니오</option>
          </select>
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateInsurance">수정</button>
          <button class="button-delete" @click="deleteInsurance">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import DailyCheckModule from "@/lib/DailyCheckModule";
  export default {
    name: "InsuranceUpdateDelete",
    data() {
      return {
        form: {
          uuid: "", // 기존 'id' 사용 그대로 유지
          model_ID: "",
          model:"",
          name: "",
          expire_date: "",
          sms_YN: true,
        },
        droneModels: [], // 드론 모델 리스트
      };
    },
    computed: {
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
    },
    created() {
      this.loadInsuranceData(); // 기존 함수명 유지
      this.loadDroneModels(); // 드론 모델 데이터를 가져오는 함수
    },
    methods: {
      async loadInsuranceData() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
          const { id } = this.$route.params; // 라우터 파라미터에서 id 가져오기
  
          const response = await axios.get(`/insurance/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.data) {
            this.form = {
              uuid: response.data.uuid, // 기존 'uuid'로 유지
              model: response.data.model,
              model_ID: response.data.model_ID,
              name: response.data.name,
              expire_date: response.data.expire_date.split("T")[0], // 날짜 형식 처리
              sms_YN: response.data.sms_YN,
            };
          } else {
            Swal.fire("오류", "보험 데이터를 로드할 수 없습니다.", "error");
          }
        } catch (error) {
          console.error("보험 데이터 로드 오류:", error);
          Swal.fire("오류", "보험 데이터를 불러오지 못했습니다.", "error");
        }
      },
      async loadDroneModels() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          const response = await axios.get("/drones/type", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          this.droneModels = response.data;
        } catch (error) {
          console.error("드론 모델 데이터 로드 오류:", error);
          Swal.fire("오류", "드론 모델 데이터를 로드할 수 없습니다.", "error");
        }
      },
      async updateInsurance() {
        if (!this.form.model_ID || !this.form.name || !this.form.expire_date) {
          Swal.fire("오류", "모든 필드를 채워주세요.", "error");
          return;
        }
  
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          await axios.put(
            `/insurance/${this.form.uuid}`,
            {
              model_ID: this.form.model_ID,
              name: this.form.name,
              expire_date: new Date(`${this.form.expire_date}T23:59:59`),
              sms_YN: this.form.sms_YN,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          Swal.fire("성공", "보험이 성공적으로 수정되었습니다.", "success");
          //sms 데이터 재생성
          await DailyCheckModule.recreateSmsData(this.senderNumber);
          this.goToList();
        } catch (error) {
          console.error("수정 실패:", error);
          Swal.fire("오류", "수정 중 문제가 발생했습니다.", "error");
        }
      },
      async deleteInsurance() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          await axios.delete(`/insurance/${this.form.uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          Swal.fire("성공", "보험이 성공적으로 삭제되었습니다.", "success");

          //sms 데이터 재생성
          await DailyCheckModule.recreateSmsData(this.senderNumber);
          
          this.goToList();
        } catch (error) {
          console.error("삭제 실패:", error);
          Swal.fire("오류", "삭제 중 문제가 발생했습니다.", "error");
        }
      },
      goToList() {
        this.$router.push("/insurance-read");
      },
    },
  };
  </script>
  
  <style scoped>
  /* 동일한 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box,
  .select-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 300px;
  }
  
  .input-select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-update {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  